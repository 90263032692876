import React from 'react';
import { TrackingProvider } from '@volvo-cars/tracking';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import HydrateInView from '@vcc-www/rendering/HydrateInView';
import JSSPlaceholderRenderer from '../../JSSPlaceholderRenderer';
import { PageLayoutProps } from '../../PageLayoutProps';

const NewsOurStoryPage: React.FC<PageLayoutProps> = ({
  header,
  placeholders,
}) => {
  const { marketName, locale, regionCode } = useCurrentMarketSite();
  return (
    <TrackingProvider
      market={marketName}
      locale={locale}
      trackPageLoad
      enableReactTracking={false}
      marketLanguage={locale}
      countryCode={regionCode}
    >
      {header}
      <main>
        <JSSPlaceholderRenderer
          renderings={placeholders['model-intro']}
          extend={{ zIndex: 999, position: 'relative' }}
        />
        <JSSPlaceholderRenderer
          renderings={placeholders['local-sub-menu']}
          withStickyChildren
        />
        <JSSPlaceholderRenderer renderings={placeholders['model-intro-2']} />
        <JSSPlaceholderRenderer renderings={placeholders['hero']} />
        <JSSPlaceholderRenderer renderings={placeholders['text-statement']} />
        <JSSPlaceholderRenderer renderings={placeholders['callouts']} />
        <JSSPlaceholderRenderer renderings={placeholders['model-intro-3']} />
        <HydrateInView>
          <JSSPlaceholderRenderer renderings={placeholders['model-stats']} />
          <JSSPlaceholderRenderer
            renderings={placeholders['product-list-carousel']}
          />
          <JSSPlaceholderRenderer renderings={placeholders['model-intro-4']} />
          <JSSPlaceholderRenderer
            renderings={placeholders['slider-with-icons']}
          />
        </HydrateInView>
        <HydrateInView>
          <JSSPlaceholderRenderer renderings={placeholders['model-intro-5']} />
          <JSSPlaceholderRenderer
            renderings={placeholders['scrollable-accordion']}
          />
          <JSSPlaceholderRenderer renderings={placeholders['model-intro-6']} />
          <JSSPlaceholderRenderer renderings={placeholders['pdp-promotions']} />
          <JSSPlaceholderRenderer renderings={placeholders['model-intro-7']} />
          <JSSPlaceholderRenderer renderings={placeholders['video']} />
          <JSSPlaceholderRenderer
            renderings={placeholders['image-with-text']}
          />
        </HydrateInView>
        <JSSPlaceholderRenderer
          renderings={placeholders['news-featured-articles']}
        />
      </main>
    </TrackingProvider>
  );
};

export default NewsOurStoryPage;
